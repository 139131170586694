<template>
  <div>
    <Widget class="h-auto">
      <template #title>Subir revista</template>
      <p>Suba un archivo de tipo PDF</p>

      <FormUploader @onChange="upload" class="btn btn-outline-primary">
        <div class="img-sel-button">
          <i class="fa-solid fa-file-arrow-up upload-icon"></i>
          <small> Click para subir archivo</small>
        </div>
      </FormUploader>

      <section id="revistas-list">
        <ul>
          <li v-for="(revista, index) in revistas" :key="index">
            <i class="fa-solid fa-file"></i>
            <p>{{ revista }}</p>
            <i class="fa-solid fa-trash trash btn btn-outline-primary" @click="Delete(revista)"></i>
          </li>
        </ul>
      </section>
    </Widget>
  </div>
</template>

<script>
/*eslint-disable*/
import Widget from "@/components/Widget.vue";
import FormUploader from "@/components/Form/FormUploader.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  async mounted() {
    await this.getRevistas();
  },
  components: {
    FormUploader,
    Widget
  },
  methods: {
    ...mapActions(["getRevistas", "deleteRevista"]),
    upload({file, evt}) {
      const fd = new FormData();
      fd.append("revista", file);
      this.$store.dispatch("uploadRevista", fd)
    },
    Delete (item) {
      this.$alert
          .fireConfirm("Borrar Revista", "Esta seguro que desea continuar? Se eliminara la revista por completo")
          .then(() => {
            this.deleteRevista({revista: item});
          });
    }
  },
  computed: {
    ...mapGetters(["revistas"]),
  }
}
</script>


<style scoped lang="scss">
  .btn {
    cursor: pointer;
  }

  .upload-icon {
    margin-right: 1em;
  }

  #revistas-list {
    margin-top: 1em;
    ul {
      list-style: none;
      li {
        position: relative;
        display: flex;
        align-items: center;
        i {
          margin: 0 1em;
        }

        .trash {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          border: none;
        }
      }
    }
  }

  p {
    margin: 0;
  }
</style>
